import * as moment from 'moment';

import { SCHEDULE_CONSTANTS } from '@app/shared/constants/app.constants';
/**
    * Apply the format settings to given time string
    * @method      formatOutputTime
    * @argument    timeString: string
    * @returns     string
    */
function formatScheduleTime(timeString: string): string {
    let formattedTime = timeString;
    if (moment(timeString, SCHEDULE_CONSTANTS.timeParseFormat).isValid()) {
        formattedTime = moment(timeString, SCHEDULE_CONSTANTS.timeParseFormat).format(SCHEDULE_CONSTANTS.timeOutputFormat);
    }

    return formattedTime;
}

export default formatScheduleTime;
